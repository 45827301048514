<template>
  <v-app>

    <v-app-bar
      app
      color="blue-grey"
      dark
    >
      <v-btn icon @click="openProjects">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Selfie Mosaic Designer</v-toolbar-title>

      <div class="pixel__navbar">
        <div class="pixel__navbar-item" v-if="loaded">
          <img class="pixel__color" :src="currentColor ? currentColor.preview_url : ''">
        </div>

        <div class="pixel__navbar-item" v-for="item in availableTools">
          <button class="pixel__navbar-btn"
                  v-bind:class="{'active': tool === item.tool}"
                  @click="selectTool(item.tool)"
                  @shortkey="selectTool(item.tool)"
                  v-shortkey="item.shortkey"
                  :title="`Shortkey: ` + item.shortkey">
            <v-icon style="font-size: 16px;"
                    :color="tool === item.tool ? '#000000' : '#ffffff'">
              fas {{ item.fa_icon }}
            </v-icon>
          </button>
        </div>

        <div class="pixel__navbar-item-group">
          <div class="pixel__navbar-item" style="margin-right: 0">
            <button class="pixel__navbar-btn" @click="zoomIn" v-shortkey="['meta','=']"
                    @shortkey="zoomIn()" title="CMD + =">
              <i class="fas fa-search-plus"></i>
            </button>
          </div>
          <div class="pixel__navbar-item" style="margin-left: 8px" v-shortkey="['meta','-']"
               @shortkey="zoomOut()" title="CMD + -">
            <button class="pixel__navbar-btn" @click="zoomOut">
              <i class="fas fa-search-minus"></i>
            </button>
          </div>
          <div class="pixel__navbar-item" v-shortkey="['meta','0']"
               @shortkey="zoomReset()" title="CMD + 0">
            <button class="pixel__navbar-btn" @click="zoomReset"
                    style="width: auto; padding: 0 8px; margin-left: 8px;">
              100%
            </button>
          </div>
          <div class="pixel__navbar-item" style="margin-left: 8px">
            <button class="pixel__navbar-btn" @click="zoomToFit"
                    :title="$t('designer.zoom_to_fit')">
              <i class="fab fa-firstdraft"></i>
            </button>
          </div>
        </div>

        <div class="pixel__navbar-item-group">
          <div class="pixel__navbar-item" style="margin-right: 0">
            <button class="pixel__navbar-btn" @click="undo" :disabled="!canUndo"
                    v-shortkey="['meta','z']"
                    @shortkey="undo()" title="CMD + Z">
              <i class="fas fa-undo"></i>
            </button>
          </div>
          <div class="pixel__navbar-item" style="margin-left: 8px">
            <button class="pixel__navbar-btn" @click="redo" :disabled="!canRedo"
                    v-shortkey="['meta','u']"
                    @shortkey="redo()" title="CMD + U">
              <i class="fas fa-redo"></i>
            </button>
          </div>
        </div>

        <!--        <div class="pixel__navbar-item-group">-->
        <!--          <div class="pixel__navbar-item" style="margin-right: 0">-->
        <!--            <button class="pixel__navbar-btn" @click="exportProject">-->
        <!--              <i class="fas fa-file-export"></i>-->
        <!--            </button>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

      <!--      <v-btn class="ma-2" @click="previewProject" color="blue-grey lighten-1">-->
      <!--        <v-divider></v-divider>-->
      <!--        {{ $t("common.preview") }}-->
      <!--      </v-btn>-->
      <v-divider></v-divider>
      <!--      <v-progress-circular-->
      <!--        indeterminate-->
      <!--        color="white"-->
      <!--        style="margin-right: 20px;" v-if="isLongOperationInProgress"></v-progress-circular>-->

      <!--      <v-btn class="pixel__save-btn" @click="pixelateProject" color="blue-grey lighten-1">-->
      <!--        <v-divider></v-divider>-->
      <!--        Repixelate-->
      <!--      </v-btn>-->
      <v-divider></v-divider>
      <v-btn class="pixel__save-btn" @click="saveProject" color="blue-grey lighten-1">
        <v-divider></v-divider>
        {{ $t("common.save") }}
      </v-btn>
    </v-app-bar>


    <div class="pixel__wrap" v-if="loaded">
      <div class="pixel__left-sidebar">
        <Layers/>
      </div>
      <div class="pixel__content" :class="`pixed__tool--${tool}`" ref="canvasContainerWrap">

        <div class="pixel__canvas-container" ref="canvasContainer">
          <div class="pixel__canvas-wrap" style="border: 1px solid #d7ccc8;"
               v-bind:style="[{'width': `${canvasSizeWithZoom.width + 1}px`},
                                 {'height': `${canvasSizeWithZoom.height+ 1}px`},
                                 ]">
            <v-progress-linear
            style="position: absolute;z-index: 10000;left: 0;top: 0;"
            indeterminate
            color="blue-grey darken-1"
            v-if="isLoading == true"
          ></v-progress-linear>
            <Canvas v-for="(layer, index) in project.layers"
                    :key="layer.id"
                    :index="index"/>
            <div class="pixel__overlay-wrap" v-if="currentLayer.preview_url" style="z-index: 100;"
                 v-bind:style="[{'opacity': `${overlayAlpha}`},]">
              <img :src="currentLayer.preview_url" alt=""/>
            </div>
          </div>

        </div>
      </div>
      <div class="pixel__right-sidebar">
        <Fileuploader/>
        <v-slider class="pixel__slider my-0 py-0" :label="$t('designer.opacity')" v-model="overlayAlpha"
                  step="0.05"
                  min="0" max="1"
                  ticks></v-slider>
        <div>
          <v-text-field class="my-0 py-0" light :label="$t('common.name')"
                        v-model="project.title"></v-text-field>
          <Colors/>
          <v-row>
            <v-col class="my-0 py-0">
              <v-select v-model="project.meta.canvas_width"
                        :items="availableCanvasSizes"
                        :label="$t('designer.canvas_width')"
                        @change="changeLayoutSettings"
              ></v-select>
            </v-col>
            <v-col class="my-0 py-0">
              <v-select v-model="project.meta.canvas_height"
                        :items="availableCanvasSizes"
                        :label="$t('designer.canvas_height')"
                        @change="changeLayoutSettings"
              ></v-select>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="my-0 py-0">
              <v-select v-model="project.meta.plate_size"
                        :items="availablePlateSizes"
                        :label="$t('designer.plate_size')"
                        @change="changeLayoutSettings"
              ></v-select>
            </v-col>
            <v-col class="my-0 py-0" cols="6">
              <v-select
                :label="$t('designer.colors_count')"
                :items="availableColorsCount"
                v-model="currentLayer.settings.colors_count"
                @change="changeLayerSettings"
              ></v-select>
            </v-col>

          </v-row>
          <v-row>
            <v-col class="my-0 py-0" cols="12">
              <v-select
                :label="$t('designer.algorithm')"
                :items="availableAlgorithms"
                v-model="currentLayer.settings.algorithm"
                @change="changeLayerSettings"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">

            <label class="caption">{{ $t("designer.contrast") }}: <span v-text="currentLayer.settings.contrast"></span></label>
            <v-slider class="pixel__slider" min="-40" max="40" dense
                      :thumb-size="20"
                      thumb-label
                      v-model="currentLayer.settings.contrast" @change="changeLayerSettings"></v-slider>
          </v-col>
          <v-col class="pa-0 ma-0">
            <label class="caption">{{ $t("designer.brightness") }}: <span v-text="currentLayer.settings.brightness"></span></label>
            <v-slider class="pixel__slider" min="-100" max="100"
                      v-model="currentLayer.settings.brightness" @change="changeLayerSettings"
                      dense
                      thumb-label></v-slider>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0"><label class="caption">{{ $t("designer.saturation") }}: <span
            v-text="currentLayer.settings.saturation"></span></label>
            <v-slider class="pixel__slider" min="-100" max="100"
                      v-model="currentLayer.settings.saturation" @change="changeLayerSettings"
                      dense
                      thumb-label></v-slider>
          </v-col>
          <v-col class="pa-0 ma-0"><label class="caption">{{ $t("designer.hue") }}: <span v-text="currentLayer.settings.hue"></span></label>
            <v-slider class="pixel__slider pa-0 ma-0" min="0" max="360"
                      v-model="currentLayer.settings.hue"
                      dense
                      @change="changeLayerSettings" thumb-label></v-slider>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0"><label class="caption">{{ $t("designer.unshark_mask_kernel_size") }}: <span
            v-text="currentLayer.settings.unsharp_mask_kernel_size"></span></label>
            <v-slider class="pixel__slider" min="1" max="13"
                      v-model="currentLayer.settings.unsharp_mask_kernel_size" @change="changeLayerSettings"
                      dense
                      step="2"
                      thumb-label></v-slider>
          </v-col>
          <v-col class="pa-0 ma-0"><label class="caption">{{ $t("designer.unshark_mask_kernel_sigma") }}: <span
            v-text="currentLayer.settings.unsharp_mask_sigma_x10"></span></label>
            <v-slider class="pixel__slider pa-0 ma-0" min="1" max="50"
                      v-model="currentLayer.settings.unsharp_mask_sigma_x10"
                      dense
                      @change="changeLayerSettings" thumb-label></v-slider>
          </v-col>
        </v-row>
          <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0"><label class="caption">{{ $t("designer.constraint_prc") }}: <span
            v-text="currentLayer.settings.constraint_prc"></span></label>
            <v-slider class="pixel__slider" min="90" max="200"
                      v-model="currentLayer.settings.constraint_prc" @change="changeLayerSettings"
                      dense
                      step="1"
                      thumb-label></v-slider>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>

</template>


<script>
import Vue from 'vue'
import {DesignTool} from '@/store/modules/design'
import {mapActions, mapGetters, mapState} from "vuex";

Vue.use(require('vue-shortkey'), {prevent: ['input', 'textarea']});

export default {
  name: "Design",
  data() {
    return {
      overlayAlpha: 0,
      availableCanvasSizes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      availablePlateSizes: [{text: '8 x 8', value: 8}, {
        text: '24 x 24',
        value: 24
      }, {text: '32 x 32', value: 32}, {text: '50 x 50', value: 50}],
      availableAlgorithms: [
        {
          'value': '2020_12_01_color',
          'text': 'Nearest Color'
        },
        {
          'value': '2020_12_01',
          'text': 'Grayscale Y'
        },
        {
          'value': '2020_21_01_constraint',
          'text': 'Grayscale Y Constraint'
        },
        {
          'value': '2020_21_01_color_constraint',
          'text': 'Nearest Color Constraint'
        },
      ],
      availableTools: [
        {
          tool: DesignTool.Brush,
          icon: "brush",
          fa_icon: 'fa-paint-brush',
          title: "Кисть",
          shortkey: ['b'],
        },
        {
          tool: DesignTool.Fill,
          icon: "fillico",
          fa_icon: 'fa-fill',
          title: "Заливка",
          shortkey: ['g'],
        },
        {
          tool: DesignTool.Replace,
          icon: "change",
          fa_icon: 'fa-swatchbook',
          title: "Замена цвета",
          shortkey: ['r'],
        },
        {
          tool: DesignTool.Erase,
          icon: "eraser",
          fa_icon: 'fa-eraser',
          title: "Стерка",
          shortkey: ['e'],
        },
      ],

      autosave_time: 15,
      autosave_timer: null,

      loaded: false,
    }
  },
  components: {
    Layers: () => import('@/components/designer/Layers.vue'),
    Colors: () => import('@/components/designer/Colors.vue'),
    Canvas: () => import('@/components/designer/Canvas.vue'),
    Fileuploader: () => import('@/components/designer/Fileuploader.vue'),
  },
  computed: {
    availableColorsCount() {
      // console.log(this.availableColors.length)
      // console.log(Array.from({length: this.availableColors.length}, (_, i) => ({text: String(i + 1), value: i + 1})))
      return Array.from({length: this.availableColors.length}, (_, i) => ({text: String(i + 1), value: i + 1}));
    },
    keymap() {
      return {
        'ctrl+z': this.undo()
      }
    },
    ...mapGetters('design', ['currentLayer', 'canvasSizeWithZoom', 'canUndo', 'canRedo']),
    ...mapState('colors', ['colors', 'palettes', 'colorsImages']),
    ...mapState('design', ['layerIndex', 'tool', 'project', 'currentColor']),
    ...mapState('app', ['isLoading']),
    availableColors() {
      return this.colors.filter(x => x.palettes.includes(this.project.meta.palette) && x.is_deleted == false);
    }
  },
  created() {
    // console.log('Created designer: ' + this.$route.params.id + ', reseting store');
  },
  async mounted() {
    this.loaded = false
    this.$store.commit('app/setLoading', true)
    this.initClear()
    const promises = []
    promises.push(this.loadProject(this.$route.params.id))
    promises.push(this.loadColors())
    await Promise.all(promises)
    this.loaded = true
    this.$store.commit('design/setColor', this.availableColors[0])
    this.$store.commit('app/setLoading', false)
    this.startTimer()
  },
  destroyed() {
    this.stopTimer()
  },
  methods: {
    ...mapActions('design', ['initClear', 'loadProject', 'editFile']),
    ...mapActions('colors', ['loadColors']),
    // exportProject() {
    //   function download(id) {
    //     var element = document.createElement('a');
    //     var link = "/api/projects/preview/" + id;
    //     console.log(link);
    //     element.setAttribute('href', link);
    //     element.setAttribute('download', 'export-2.png');
    //
    //     element.style.display = 'none';
    //     document.body.appendChild(element);
    //
    //     console.log(element);
    //     element.click();
    //
    //     document.body.removeChild(element);
    //   }
    //
    //
    //   download(this.project.id);
    // },
    undo() {
      if (!this.canUndo) {
        return;
      }
      this.$store.commit('design/historyUndo')
    },
    redo() {
      if (!this.canRedo) {
        return;
      }
      this.$store.commit('design/historyRedo')
    },
    async saveProject() {
      this.$store.commit('app/setLoading', true)
      this.autosave_time = 60;
      await this.$api.projects.save(this.project)
      this.$store.commit('app/setLoading', false)
    },
    previewProject() {
      this.$router.push({name: 'home'});
    },
    openProjects() {
      this.$router.push({name: 'home'});
    },
    zoomIn() {
      this.$store.dispatch('design/zoomIn')
    },
    zoomOut() {
      this.$store.dispatch('design/zoomOut')
    },
    zoomReset() {
      this.$store.dispatch('design/zoomReset')
    },
    zoomToFit() {
      let container = this.$refs['canvasContainerWrap'];
      let minContainerSize = Math.min(container.clientWidth, container.clientHeight);
      let maxCanvasSize = Math.max(this.canvasSizeWithZoom.width, this.canvasSizeWithZoom.height);
      let zoomFactor = minContainerSize / maxCanvasSize - 0.05;
      this.$store.dispatch('design/zoom', zoomFactor);
    },
    selectTool(tool) {
      console.log(tool)
      this.$store.commit('design/setTool', tool)
    },
    async changeLayoutSettings() {
      let allPromises = [];
      for (let [index, layer] of this.project.layers.entries()) {
        if (layer.file_id == undefined) {
          console.log('file_id is undefined')
          // let size = state.project.meta.canvas_height * state.project.meta.canvas_width * state.project.meta.plate_size * state.project.meta.plate_size;
          // commit(DESIGNER_EDIT_FILE, {layerIndex: index, data: Array.from({length: size}).fill(0)});
          continue;
        }

        allPromises.push(this.editFile({layerIndex: index}))
      }
      await Promise.all(allPromises)
    },
    async changeLayerSettings() {
      await this.editFile()
    },
    // pixelateProject() {
    //   this.$store.dispatch(DESIGNER_EDIT_FILE);
    // },
    startTimer() {
      this.autosave_timer = setInterval(() => {
        this.autosave_time--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.autosave_timer);
    }
  },
  watch: {
    autosave_time(time) {
      if (time === 0) {
        this.saveProject();
      }
    }
  },
}
</script>

<style lang="scss" scoped>
h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

* {
  box-sizing: border-box;
}

$rightSidebarWidth: 260px;

.pixel {
  &__right-sidebar, &__left-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    width: $rightSidebarWidth;
    height: 100vh;
    box-shadow: 0 0 1px 2px rgba(#000, .1);
    padding: 14px 14px 84px;
    margin-top: 60px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__left-sidebar {
    right: auto;
    left: 0;
    width: 140px;
  }

  &__title {
    margin-bottom: 10px;
    display: flex;
  }

  &__slider {
    .v-input__slot {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .v-messages {
      display: none;
    }

    .v-label {
      transform: scale(0.75) translate(-11px, 0);
    }

    .v-slider.v-slider--horizontal {
      width: 100%;
      margin: 0;
    }
  }

  &__block-wrap {
    margin-bottom: 16px;
  }

  &__content {
    width: calc(100% - #{$rightSidebarWidth} - 140px);
    height: calc(100vh - 60px);
    background: rgba(0, 0, 0, .2);
    margin: 60px $rightSidebarWidth 0 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__canvas-wrap {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    /*transition: all .5s ease-in-out;*/
    transition: background-image .2s ease-in-out;
    position: relative;
  }

  &__canvas-container {
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    padding: 8px;
    border-radius: 2px;
    box-shadow: 0 0 10px 3px rgba(#000, .1);
    max-width: 100%;
    max-height: calc(100vh - 60px);
    overflow: auto;
  }

  &__canvas-item {
    transition: background-image .2s ease-in-out;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  &__overlay-wrap {
    width: 100%;
    height: 100%;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
}

.pixel {
  &__navbar {
    padding: 0 14px;
    width: 100%;
    z-index: 2;
    height: 60px;
    display: flex;
    align-items: center;

    .pixel__color {
      width: 36px;
      height: 36px;
      box-shadow: 0 0 0 2px orange;
    }
  }

  &__navbar-item {
    margin-left: 8px;
    display: flex;
    align-items: center;

    &:first-child {
      margin-right: 14px;
      margin-left: 2px;
    }
  }

  &__navbar-btn {
    border-radius: 6px;
    border: 1px solid #fff;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
    outline: none;

    svg path {
      transition: all .2s ease-in-out;
    }

    &:hover {
      background: rgba(#fff, .5);
    }

    &.active {
      background: #fff;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &__navbar-item-group {
    margin-left: 60px;
    display: flex;

    .pixel__navbar-item {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__save-btn {
    margin-left: auto;
  }
}

.pixed__tool {
  &--DESIGNER_TOOL_MOUSE {
    .pixel__canvas-container {
      cursor: default;
    }
  }
}
</style>
